export default {
  components: {},
  name: "message",
  data() {
    return {
      messageOpen: 1
    };
  },
  methods: {
    init() {
      let messageOpen = this.getStore("messageOpen");
      if (messageOpen != "" && messageOpen != undefined && messageOpen != null) {
        this.messageOpen = Number(messageOpen);
      } else {
        this.setStore("messageOpen", 1);
      }
    },
    changeMessage(v) {
      this.setStore("messageOpen", v);
    }
  },
  mounted() {
    this.init();
  }
};